import React, { createContext, useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'
import useAuthState from '../stores/auth'

const VideoChatContext = createContext()

function VideoChatContextProvider({ children }) {
  const { getUserId } = useAuthState()

  const [callAccepted, setCallAccepted] = useState(false)
  const [callEnded, setCallEnded] = useState(false)
  const [stream, setStream] = useState()
  const streamRef = useRef()
  const myVideo = useRef()
  const userVideo = useRef()
  const myPeer = useRef()
  const [peer, setPeer] = useState(null)

  const [settings, setSettings] = useState({
    video: true,
    audio: true,
  })

  const answerCall = dial => {
    setCallAccepted(true)
    dial.answer(streamRef.current)

    dial.on('stream', remoteStream => {
      userVideo.current.srcObject = remoteStream
    })

    dial.on('error', error => {
      console.log(
        '🚀 ~ file: videoChatContext.jsx:37 ~ dial.on ~ error:',
        error,
      )
      const message = `'Error occurred:' ${error}`
      toast.error(message)
    })

    dial.on('close', () => {
      // Call ended, perform cleanup or handle the event
      setCallAccepted(false)
      setCallEnded(true)
      dial.close()
    })
  }

  useEffect(() => {
    const initializePeer = async () => {
      try {
        const peerjs = await import('peerjs')
        setPeer(peerjs)
      } catch (error) {
        console.log(
          '🚀 ~ file: videoChatContext.jsx:47 ~ initializePeer ~ error:',
          error,
        )
        const message = `Error importing Peer.js: ${error}`
        toast.error(message)
      }
    }

    initializePeer()

    const getMediaStream = async () => {
      try {
        const mediaConstraints = {
          video: settings.video,
          audio: settings.audio,
        }

        const localStream = await navigator.mediaDevices.getUserMedia(
          mediaConstraints,
        )
        setStream(localStream)
        streamRef.current = localStream
        if (myVideo.current) {
          myVideo.current.srcObject = localStream
        }
      } catch (error) {
        console.log(
          '🚀 ~ file: videoChatContext.jsx:83 ~ getMediaStream ~ error:',
          error,
        )
        const message = `'Error accessing media devices:' ${error}`
        toast.error(message)
      }
    }

    getMediaStream()

    if (peer) {
      const thisPeer = new peer.Peer(getUserId(), {
        host: '/localhost',
        port: '3000',
        path: '/peerjs',
      })

      myPeer.current = thisPeer

      myPeer.current.on('call', answerCall)

      return () => {
        myPeer.current.off('call', answerCall)

        myPeer.current.destroy()
      }
    }
  }, [peer])

  const callUser = id => {
    if (myPeer.current && stream) {
      const dial = myPeer.current.call(id, stream)

      dial.on('stream', remoteStream => {
        setCallAccepted(true)

        userVideo.current.srcObject = remoteStream
      })

      dial.on('close', () => {
        // Call ended, perform cleanup or handle the event
        setCallAccepted(false)
        setCallEnded(true)
      })

      dial.on('error', error => {
        console.log(
          '🚀 ~ file: videoChatContext.jsx:130 ~ dial.on ~ error:',
          error,
        )
        const message = `'Error occurred:' ${error}`
        toast.error(message)
      })
    }
  }

  const leaveCall = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop())
    }
    myPeer.current.destroy()
    setCallAccepted(false)
    setCallEnded(true)
  }

  function toggleVideo() {
    if (stream) {
      const videoTracks = stream.getVideoTracks()
      if (videoTracks.length > 0) {
        // Toggle the video track on or off
        videoTracks[0].enabled = !videoTracks[0].enabled
      }
    }
  }

  function toggleAudio() {
    if (stream) {
      const audioTracks = stream.getAudioTracks()
      if (audioTracks.length > 0) {
        // Toggle the audio track on or off
        audioTracks[0].enabled = !audioTracks[0].enabled
      }
    }
  }

  useEffect(() => {
    toggleVideo()
  }, [settings.video])

  useEffect(() => {
    toggleAudio()
  }, [settings.audio])

  return (
    <VideoChatContext.Provider
      value={{
        callAccepted,
        myVideo,
        userVideo,
        stream,
        callEnded,
        callUser,
        leaveCall,
        settings,
        setSettings,
        setCallAccepted,
        setCallEnded,
      }}
    >
      {children}
    </VideoChatContext.Provider>
  )
}

export { VideoChatContextProvider, VideoChatContext }
